import { Typography } from '@mui/material'
import React from 'react'
import '../styles/Consulta.css'
import '../styles/Pagos.css'
import pagos from '../img/pPagos.jpg'

export const Ppagos: React.FC<{}> = () => {
    return(
        <div className="consulta-container">
        <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"30px"}}>
            <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                PUNTOS DE PAGO
            </Typography>
            <img src={pagos} alt="pagos" className='pagos' />
        </div>
        </div>
    )
}