import React from 'react';
import fy1 from '../img/flyer1.jpeg'
import fy2 from '../img/flyer2.jpeg'
import fy3 from '../img/flyer3.jpeg'
import fy4 from '../img/flyer4.jpeg'
import '../styles/flyer.css'; 

export const Flyer: React.FC<{}> = () => {

return(
    <div>
        <section>
            <img src={fy1} alt="flyer" />
            <img src={fy2} alt="flyer" />
            <img src={fy3} alt="flyer" />
            <img src={fy4} alt="flyer" />
        </section>

    </div>
)
}