import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, MenuItem, Typography } from '@mui/material';

interface Horario {
    id: number;
    sector: string;
    dia: string;
    horario: string;
    circuito: string;
}

export const Horaioss: React.FC<{}> = () => {
    const [horarios, setHorarios] = useState<Horario[]>([]);
    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroDireccion, setFiltroDireccion] = useState('');
    const currencies = [
        { value: 'CIRCUITO MARIA PIEDAD', label: 'CIRCUITO MARIA PIEDAD' },
        { value: 'CIRCUITO RECREO', label: 'CIRCUITO RECREO' },
        { value: 'CIRCUITO CENTRO', label: 'CIRCUITO CENTRO' },
        { value: 'CIRCUITO ABEL GILBERT 3', label: 'CIRCUITO ABEL GILBERT 3' },
        { value: 'CIRCUITO HELECHOS', label: 'CIRCUITO HELECHOS' },
        { value: 'CIRCUITO SECTOR INDUSTRIAL', label: 'CIRCUITO SECTOR INDUSTRIAL' },
        { value: 'CIRCUITO HÉCTOR COBOS', label: 'CIRCUITO HÉCTOR COBOS' },
        { value: 'CIRCUITO PANORAMA', label: 'CIRCUITO PANORAMA' },
        { value: 'CIRCUITO MODULAR', label: 'CIRCUITO MODULAR' },
        { value: 'CIRCUITO CENTRO VIAL', label: 'CIRCUITO CENTRO VIAL' },
        { value: 'CIRCUITO PRIMAVERA 2', label: 'CIRCUITO PRIMAVERA 2' },
        { value: 'CIRCUITO 28 DE AGOSTO', label: 'CIRCUITO 28 DE AGOSTO' },
        { value: 'CIRCUITO NORTE', label: 'CIRCUITO NORTE' },
        { value: 'CIRCUITO PRIMAVERA 1', label: 'CIRCUITO PRIMAVERA 1' },
        { value: '', label: 'TODOS' },
    ];

    useEffect(() => {
        const fetchMarcaciones = async () => {
            try {
                const response = await axios.get('https://www.emapad.gob.ec/app/conexion/apiHorario/');
                setHorarios(response.data);
            } catch (error) {
                console.error("Error al obtener las marcaciones:", error);
            }
        };

        fetchMarcaciones();
    }, []);

    const marcacionesFiltradas = horarios.filter(marcacion => {
        const sector = marcacion.sector ? marcacion.sector.toLowerCase() : '';
        return sector.includes(filtroNombre.toLowerCase()) &&
               (filtroDireccion === '' || marcacion.circuito === filtroDireccion);
    });

    return (
        <div className="consulta-container">
            <div className="consulta-title">
                <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                    HORARIOS
                </Typography>
                
            </div>
        <div style={{ margin: '2%' }}>
            <TextField
                label="Filtrar por sector"
                variant="outlined"
                value={filtroNombre}
                onChange={e => setFiltroNombre(e.target.value)}
            />
            <TextField
                id="outlined-select-currency"
                select
                label="Circuito"
                value={filtroDireccion}
                defaultValue=" "
                onChange={(e) => setFiltroDireccion(e.target.value)}
                sx={{ width: '20%', marginBottom: '2%' }}
            >
                {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 20 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: 'rgba(18, 167, 241, 0.8)' }}>
                            <TableCell sx={{ width: '6px' }}>COD</TableCell>
                            <TableCell align="left" sx={{ width: '28%' }}>SECTOR</TableCell>
                            <TableCell align="left">DIA</TableCell>
                            <TableCell align="left">HORARIO</TableCell>
                            <TableCell align="left">CIRCUITO</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {marcacionesFiltradas.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{row.id}</TableCell>
                                <TableCell align="left">{row.sector}</TableCell>
                                <TableCell align="left">{row.dia}</TableCell>
                                <TableCell align="left">{row.horario}</TableCell>
                                <TableCell align="left">{row.circuito}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        </div>
    );
};
