import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import logo from '../img/emapad.png';
import { Tabss } from './tabs';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import '../styles/navbar.css'; 
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { NavLink } from 'react-router-dom';
import { Desplazo } from './desplazo';



/* DESDE AQUI SE PONE EL MOVILE APP DE LA IZQUIERDA*/
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}
const drawerWidth = 240;
const navItems = [ 'Nosotros', 'Transparencia', 'Consulta','Actualizacion','Tramites'];
/* DESDE AQUI SE PONE EL MOVILE APP DE LA IZQUIERDA*/

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

export const Navbar: React.FC<{}> = (props: Props) => {

 const HandleNavLink =(key:string) =>{

 }
  /* DESDE AQUI SE PONE EL MOVILE APP DE LA IZQUIERDA*/
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        EMAPAD-EP
      </Typography>
      <Divider />
      <List>
      <NavLink
          className={({ isActive }) => (isActive ? 'is-active' : '')}
          to={'/home/'}
          style={{ textDecoration: 'none', color: 'inherit' }}>
         <ListItem key={"item"}  disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText
               primary={"Home"} />
            </ListItemButton>
          </ListItem>
          </NavLink>
        {navItems.map((item) => (
          <NavLink
          className={({ isActive }) => (isActive ? 'is-active' : '')}
          to={'/home/'+item}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItem  key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText
               primary={item} />
            </ListItemButton>
          </ListItem>
          </NavLink>
        ))}
        
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

/* DESDE AQUI SE PONE EL MOVILE APP DE LA IZQUIERDA*/
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const EnlaceRedes =(red : string) => {
    
    if(red == "Facebook"){
      if (typeof window !== 'undefined') {
      }

    }
    if(red == "Instagram"){
      
    }
    if(red == "Twitter"){
      
    }
    if(red == "Whatsapp"){
      
    }
  } 

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          backgroundColor: '#2885E1',
          height: '50px',
          marginBottom: '24px',
        }}
      >
        <p className='pnav'>Transformamos cada gota de agua en bienestar </p>
        <a href="https://www.facebook.com/profile.php?id=61555399395143" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <button  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', borderColor: 'transparent', background: '#0AABE3', width: '40px', marginRight: '4px'}}>
        <FacebookIcon sx={{ color:'white'}} />
        </button>
        </a>
        <a href="https://www.instagram.com/emapad_ep/?hl=es-la" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <button  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', borderColor: 'transparent', background: '#0AABE3', width: '40px', marginRight: '4px'}}>
        <InstagramIcon sx={{ color:'white'}} />
        </button>
        </a>
        <a href="https://twitter.com/EMAPAD_EP" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <button  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', borderColor: 'transparent', background: '#0AABE3', width: '40px', marginRight: '4px'}}>
        <TwitterIcon sx={{ color:'white'}} />
        </button>
        </a>
        <a href="https://wa.me/593939666990" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <button style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', borderColor: 'transparent', background: '#0AABE3', width: '40px', marginRight: '10%'}}>
        <WhatsAppIcon sx={{ color:'white'}} />
        </button>
        </a>
        
    </Box>
    <AppBar position="static" sx={{backgroundColor:'white', boxShadow: 'none' }}>
      <Container maxWidth="xl" sx={{ color:'black'}}>
        <Toolbar disableGutters>
          <Box sx={{height:'50%',display: 'flex', justifyContent: 'center',alignItems: 'center', marginLeft:'-10px'}}>
            <img style={{ display: 'none', marginRight: 1,}} src={logo} alt="logo" /> 
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, color:'black'}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerToggle}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            {/* Aqui esta lo que es el menu que se utilza en celular  */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },color:'black'
              }}//en la parte de abajo en el map esta el color de lo pequeño
            >
             
                <MenuItem key='1' onClick={handleCloseNavMenu}>
                  HOla
              
                </MenuItem>
          
            </Menu>
          </Box> 
          <Box sx={{height:'25%',display: 'flex', justifyContent: 'center',alignItems: 'center', marginLeft:'-10px'}}>
            <img style={{width:'50%',}} src={logo} alt="logo" /> 
          </Box>
          
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } , marginRight:'-30px'}}>
              <Desplazo/>
          </Box>

          
        </Toolbar>
      </Container>
    </AppBar>
    <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
  </div>
  );
}

