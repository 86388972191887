import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { noticiaInterface } from "../interface/Noticias";
import axios from "axios";
import { Typography } from "@mui/material";
import '../styles/Consulta.css';
import '../styles/ViewNoticia.css';

type ViewNoticiaParams = {
    id: string; // Asume que id es de tipo string
};

export const ViewNoticia: React.FC<{}> = () => {
    const { id } = useParams<ViewNoticiaParams>(); // Captura el ID desde la URL
    const [noticiaId, setNoticias] = useState<noticiaInterface | null >(null)

    useEffect(() => {
        const obtenerNoticias = async () => {
            try {
                const response = await axios.get('https://www.emapad.gob.ec/app/conexion/api/?id='+id);
                console.log(response.data)
                setNoticias(response.data)
                console.log(noticiaId)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        obtenerNoticias();
    }, [id]);

    return (
        <div className="consulta-container">
            {noticiaId ? (
                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <Typography sx={{fontWeight:'bold',color:'#4F5051',textTransform:"uppercase"}} variant="h5" gutterBottom>
                        NOTICIA
                    </Typography>
                    <Typography sx={{fontWeight:'bold',color:'#0A93EB',textTransform:"uppercase",textAlign:"center"}} variant="h6" gutterBottom>
                        {noticiaId.Titulo}
                    </Typography>
                    <p style={{color:'#'}}>{noticiaId.Fecha}</p>
                    <img src={noticiaId.Imagen} alt="" className="imagen"/>
                    <div
                        style={{marginTop:"20px",marginBottom:"50px",textAlign:"justify"}}
                        dangerouslySetInnerHTML={{ __html: noticiaId.Contenido }}
                    />
                    {/* Agrega más detalles de la noticia como necesites */}
                </div>
            ) : (
                <div>No hay noticias disponibles</div>
            )}
        </div>
    )
}
