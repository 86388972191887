import React, { useEffect, useState } from "react";
import lv from '../img/logovertical.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import '../styles/Consulta.css'; 
import '../styles/Footer.css';



export const Piepagina: React.FC<{}> = () => {

    return(
        <div>
  <footer className="Celularfooter">
    <div style={{ marginLeft: '6%', marginRight: '6%' }}>
      <div style={{ display: 'flex', alignItems: 'center'}} className="CelularCentro">
        <img src={lv} alt="" style={{ width: '6.5%' }} />

        <div style={{ flex: 1, textAlign: 'center' }}  className="CelularLetra">
          <p style={{ color: 'white', fontWeight: 'bold' }}>
            © 2024 EMAPAD-EP | Jefatura de Tecnologías de la Información y Comunicación
          </p>
        </div>

        <div style={{ display: 'flex' }}>
        <a href="https://www.facebook.com/profile.php?id=61555399395143" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
          <button className="CelularIcon">
            <FacebookIcon sx={{ color: 'white' }} />
          </button>
          </a>
          <a href="https://www.instagram.com/emapad_ep/?hl=es-la" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
          <button className="CelularIcon">
            <InstagramIcon sx={{ color: 'white' }} />
          </button>
          </a>
          <a href="https://twitter.com/EMAPAD_EP" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
          <button className="CelularIcon">
            <TwitterIcon sx={{ color: 'white' }} />
          </button>
          </a>
          <a href="https://wa.me/593939666990" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
          <button className="CelularIcon">
            <WhatsAppIcon sx={{ color: 'white' }} />
          </button>
          </a>
        </div>
      </div>
    </div>
  </footer>
</div>

)
}