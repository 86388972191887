import React from 'react';

import { Consulta } from './components/consulta';
import { BrowserRouter as Router,Route, Routes} from 'react-router-dom';
import { Nosotros } from './components/Nosotros';
import { Navbar } from './components/navbar';
import { Home } from './components/Home';
import { Transparencia } from './components/Transparencia';
import MyMap from './components/map';
import emapadImage from './img/emapad.png'; 
import  { AppBarMovile } from './components/movilebar';
import { Piepagina } from './components/PieWeb';
import { NoticiasHome } from './components/acordionNoticias';
import { Pac } from './components/pac';
import { ViewNoticia } from './components/viewNoticia';
import { Desplazo } from './components/desplazo';
import { Ppagos } from './components/pPagos';
import { Tramite } from './components/Tramites';
import { Actualizacion } from './components/Actualizacion';
import { Rcuentas } from './components/cuentasR';
import { Horaioss } from './components/horario';


function App() {
  return (

      
     <div style={{display:'flex', flexDirection:'column',justifyContent:'center',minHeight:'100vh'}}>
       <Router basename='/'>
       <div style={{ flex: 1 }}>  {/*Este div se expandirá para ocupar el espacio restante */}
       <Navbar/>
    
         <Routes>
           <Route  path={'home'} element={<Home/>}/>
           <Route path={'home/Nosotros'} element={<Nosotros/>}/>
          <Route path={'home/Transparencia'} element={<Transparencia/>}/>
         <Route path={'home/Consulta'} element={<Consulta/>}/>
         <Route path="home/noticia/:id" element={<ViewNoticia/>} />
         <Route path="home/PuntosdePago" element={<Ppagos/>} />
         <Route path="home/Pac" element={<Pac/>} />
         <Route path="home/Tramites" element={<Tramite/>} />
         <Route path="home/Rendicion" element={<Rcuentas/>} />
         <Route path="home/Actualizacion" element={<Actualizacion/>} />
         <Route path="home/Horarios" element={<Horaioss/>} />
        </Routes>
       </div>
         <Piepagina/>
 </Router>
 
       
  
    </div>
  );
}

export default App;
