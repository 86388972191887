import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Alert, Button, Typography } from "@mui/material";
import axios from "axios";
import { SpinnerDotted } from 'spinners-react';
import emapadImage from '../img/emapad.png'; 
import '../styles/Consulta.css';  
import '../styles/nosotros.css';  
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import 'bootstrap/dist/css/bootstrap.min.css';
import m1 from '../img/m1.jpg'
import m2 from '../img/m3.jpg'
import m3 from '../img/m2.jpg'
import m4 from '../img/m4.jpg'
  
export const Nosotros: React.FC<{}> = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    
  
    return(
        <div className="consulta-container" >

        
        
            <div className="consulta-title">
                <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                    CONOCENOS
                </Typography>
                
            </div>

            <Carousel style={{marginTop:'30px',marginBottom:'30px'}}>
            <Carousel.Item interval={1500}>
                <Image src={m1} rounded className="carouselImgn" />
                <Carousel.Caption>
                {/*<h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={1500}>
                <Image src={m2} rounded className="carouselImgn"   />
                <Carousel.Caption>
                {/*<h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={1500}>
                <Image src={m3} rounded className="carouselImgn"   />
                <Carousel.Caption>
                {/*<h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={1500}>
                <Image src={m4} rounded className="carouselImgn"   />
                <Carousel.Caption>
                {/*<h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                </Carousel.Caption>
            </Carousel.Item>
            
            </Carousel>


            <Typography sx={{fontWeight:'bold',color:'#0A93EB', textAlign:"center"}} variant="h6" gutterBottom>
                HISTORIA
            </Typography>
            <p style={{textAlign:'justify'}}>
                EMAPAD-EP Fundada en 1995 como la Empresa Municipal de Agua Potable y Alcantarillado de Durán, 
                EMAPAD-EP ha evolucionado significativamente desde su creación. La ley del 2009 marcó un cambio al 
                definirla como una entidad de derecho público con autonomía. En 2010, se oficializó su actual denominación y 
                objeto social, enfocado en brindar servicios óptimos en agua potable y alcantarillado. Desde entonces se inició
                una profunda modernización, actualizando su estructura y estrategias para cumplir con la Ley Orgánica de 
                Empresas Públicas.</p>

            <Typography sx={{fontWeight:'bold',color:'#0A93EB', textAlign:"center"}} variant="h6" gutterBottom>
                MISIÓN 
            </Typography>
            <p style={{textAlign:'justify'}}>
            La misión de EMAPAD-EP es contribuir al mejoramiento de la calidad de vida de la población, a través de la 
            prestación de servicios de Agua Potable, Saneamiento, Gestión Ambiental y otros de interés público; buscando 
            la satisfacción de nuestros clientes, con eficiencia, calidad, compromiso social y ambiental.
            </p>
            <Typography sx={{fontWeight:'bold',color:'#0A93EB', textAlign:"center"}} variant="h6" gutterBottom>
                VISIÓN 
            </Typography>
            <p style={{textAlign:'justify'}}>
            Nuestra visión es ser un referente nacional en la modernización y prestación de servicios públicos por nuestra innovación, 
            calidad y la satisfacción de los clientes; garantizando la sostenibilidad de nuestra gestión, mostrándole al Ecuador y 
            al mundo que con emprendimiento y decisión se puede lograr eficiencia, eficacia y calidad en los servicios públicos.
            </p>
            <Typography sx={{fontWeight:'bold',color:'#0A93EB', textAlign:"center"}} variant="h6" gutterBottom>
                VALORES INSTITUCIONALES 
            </Typography>
            <p style={{textAlign:'justify'}}>
            EMAPAD-EP se rige por principios de ética, moral y responsabilidad social. Se enfoca en el desarrollo humano, manteniendo 
            valores democráticos y respeto a la dignidad humana. El compromiso con la eficacia, eficiencia y calidad está en el corazón 
            de nuestra operación. La responsabilidad social y el fortalecimiento del bienestar comunitario son pilares fundamentales en 
            nuestra misión de contribuir al desarrollo del país.
            </p>

        </div>
    );





}