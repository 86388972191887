import React from 'react'
import '../styles/Consulta.css'
import '../styles/Tramite.css'
import c1 from '../img/conv1.png'
import c2 from '../img/conv2.png'
import c3 from '../img/csincoac.png'
import c4 from '../img/ctotal.png'
import c5 from '../img/iconp.png'
import c6 from '../img/iconps.png'
import c7 from '../img/iconfa.png'
import c8 from '../img/iconvc.png'
import { Typography } from '@mui/material'

export const Tramite: React.FC<{}>=()=>{
    return(
        
        <div className="consulta-container">
        <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"30px"}}>
            <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                TRAMITES
            </Typography>
        </div>
        <div className='card-div'>
            <div className='card-item'>
                <div className='card-item-principal'>
                    <img src={c1} alt="30%" className='card-item-img'/>
                    <h6>COACTIVA</h6>
                    <h6>Convenio con 30% de Entrada</h6>
                </div>
                <p className='card-item-p'>Costas procesales e IVA</p>
                <p className='card-item-p'>Diferimiento del saldo </p>
                <p className='card-item-p'>Pago mensual convenio y consumo</p>

            </div>
            <div className='card-item'>
                <div className='card-item-principal'>
                    <img src={c2} alt="" className='card-item-img'/>
                    <h6>COACTIVA</h6>
                    <h6>Convenio con 50% de Entrada</h6>
                </div>
                <p className='card-item-p'>Costas procesales e IVA</p>
                <p className='card-item-p'>Emisión de cartas para desbloqueo de cuentas bancarias </p>
                <p className='card-item-p'>50% restante se debe pagar diferido o al contado</p>
            </div>
            <div className='card-item'>
                <div className='card-item-principal'>
                    <img src={c3} alt="" className='card-item-img'/>
                    <h6>COBRANZA</h6>
                    <h6>Convenio con 30% de Entrada</h6>
                </div>
                <p className='card-item-p'>Sin costas ni IVA</p>
                <p className='card-item-p'>facilidades de pago y acuerdos sin recargos adicionales</p>
                
            </div>
            <div className='card-item'>
                <div className='card-item-principal'>
                    <img src={c4} alt=""className='card-item-img' />
                    <h6>Pago Total</h6>
                </div>
                <h6 className='card-item-p'>Coactiva</h6>
                <p className='card-item-p'>Levantamiento de medidas cautelares</p>
                <p className='card-item-p'>Emisión de cartas para desbloqueo de cuentas bancarias </p>
                <h6 className='card-item-p'>Cobranza</h6>
                <p className='card-item-p'>Desbloqueo de codigo de servicio</p>
               
            </div>
        </div>

        <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"30px"}}>
            <Typography sx={{fontWeight:'bold',color:'#4F5051',textAlign:'center'}} variant="h5" gutterBottom>
                REQUISITOS PARA REALIZAR CONVENIOS
            </Typography>
        </div>
        <div className='card-div div-requisitos'>
            <div className='card-item'>
                <div className='card-item-principal'>
                    <img src={c5} alt="30%" className='card-item-img'/>
                    <h6>Titular</h6>
                </div>
                <p className='card-item-p'>Copia de cédula</p>
                

            </div>
            <div className='card-item'>
                <div className='card-item-principal'>
                    <img src={c6} alt="" className='card-item-img'/>
                    <h6>Terceras Personas</h6>
                </div>
                <p className='card-item-p'>Copia de cédula de ambas personas</p>
                <p className='card-item-p'>Carta de autorización firmada por el propietario</p>
                
            </div>
            <div className='card-item'>
                <div className='card-item-principal'>
                    <img src={c7} alt="" className='card-item-img'/>
                    <h6>Fallecimiendo del Titular</h6>
                </div>
                <p className='card-item-p'>Acta de defunción</p>
                
            </div>
            <div className='card-item'>
                <div className='card-item-principal'>
                    <img src={c8} alt=""className='card-item-img' />
                    <h6>Compra del bien</h6>
                </div>
                <p className='card-item-p'>Historial de Dominio</p>
            </div>
        </div>
        </div>
        
    )
}