import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Typography, imageListClasses } from "@mui/material";
import { red } from "@mui/material/colors";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Image } from "@mui/icons-material";
import logo from '../img/gota.png';
import { noticiaInterface } from "../interface/Noticias";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import '../styles/NoticiaCarousel.css'; 


export const NoticiasHome: React.FC<{}> = () => {
  const [slidesToShow, setSlidesToShow] = useState(4);
  const navigate = useNavigate();
    
      const [noticias, setNoticias] = useState<noticiaInterface[]>([])

      useEffect(() => {
        const obtenerNoticias = async () => {
        try {
            const response = await axios.get('https://www.emapad.gob.ec/app/conexion/api/');
                console.log(response.data)
                const noticiasInvertidas = [...response.data].reverse(); // Invierte los datos aquí
                setNoticias(noticiasInvertidas);
                console.log(noticias)  
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    obtenerNoticias();
    const handleResize = () => {
      // Establece slidesToShow a 2 si el ancho de la ventana es menor a 768px, de lo contrario a 4
      setSlidesToShow(window.innerWidth < 768 ? 2 : 4);
    };

    // Escuchar el evento de cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Llama a handleResize para establecer el valor inicial basado en el tamaño de la ventana al cargar
    handleResize();

    // Limpiar el event listener al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
      }, []);
      const handleClickNotticia = (id: string) => {
        // Navegar a la ruta de detalles con el ID del card
        navigate(`/home/noticia/${id}`);
      };

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow
      };

return(
   
<div>

<Box className="consulta-title">
       <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
               NOTICIAS
       </Typography>
   </Box>
        <Slider {...settings}>
        {noticias?.map((noticia, index) => (
          <div>
            <Card sx={{ maxWidth: '95%' , maxHeight:350}}
                  onClick={() => handleClickNotticia(noticia.id)}>
                <CardHeader 
                    title={
                        <Typography variant="h6" fontSize="16px" className="truncateTitle">
                          {noticia.Titulo}
                        </Typography>
                      }
                    subheader={
                        <Typography variant="body2" color="text.secondary" fontSize="12px">
                          {noticia.Fecha}
                        </Typography>
                      }
                />
                <CardMedia
                    component="img"
                    height="194"
                    image={noticia.Imagen}
                    alt="Paella dish"
                />
                <CardContent>
                <Typography variant="body2" color="text.secondary" className="truncate" dangerouslySetInnerHTML={{ __html: noticia.Contenido }} />
                </CardContent>
                <IconButton aria-label="add to favorites">
                <FavoriteBorderIcon />
                </IconButton>
              
        </Card>
          </div>
))}

        </Slider>
      </div>
)
}