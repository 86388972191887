import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { List, ListItem } from "@mui/material";
import logo from "../img/emapad.png"
import '../styles/Consulta.css'; 

export const Rcuentas: React.FC<{}> = () => {
    return(
        <div className="consulta-container">
            <div className=" consulta-title">
                <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                    RENDICION DE CUENTAS
                 </Typography>
            </div>
           
            <div className="carousel">
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                    <Typography sx={{fontWeight:'bold',color:'#4F5051',fontSize:'14'}}  gutterBottom>
                    2019
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <List >
                            <ListItem sx={{borderColor:'#E8F8FD',borderWidth:'1px',borderStyle:'solid',borderRadius:'10px'}}>
                                <p /*onClick={() =>abrirNuevaPagina(mes,informacion,'nada')}*/ style={{ cursor: 'pointer' }}>
                                <a href="https://www.emapad.gob.ec/app/contenido/Informe%20anual%20Procesos%20Rendicion%20de%20Cuentas/.2019/INFORME%20DE%20RENDICION%20DE%20CUENTAS%20EMAPAD%20EP%202019.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'black'}}>
                                    INFORME RENDICION DE CUENTA EMAPAD-EP 2019
                                </a>
                                </p>
                            </ListItem>
                    </List>
                    </AccordionDetails>
                    
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                    <Typography sx={{fontWeight:'bold',color:'#4F5051',fontSize:'14'}}  gutterBottom>
                    2020
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <List >
                            <ListItem sx={{borderColor:'#E8F8FD',borderWidth:'1px',borderStyle:'solid',borderRadius:'10px'}}>
                                <p /*onClick={() =>abrirNuevaPagina(mes,informacion,'nada')}*/ style={{ cursor: 'pointer' }}>
                                <a href="https://www.emapad.gob.ec/app/rendiciondecuent/INFORME%20FINAL%20DE%20RENDICI%c3%93N%20DE%20CUENTAS%20A%c3%91O%20FISCAL%202020.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'black'}}>
                                    INFORME RENDICION DE CUENTA EMAPAD-EP 2020
                                </a>
                                </p>
                            </ListItem>
                    </List>
                    </AccordionDetails>
                    
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                    <Typography sx={{fontWeight:'bold',color:'#4F5051',fontSize:'14'}}  gutterBottom>
                    2021
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <List >
                            <ListItem sx={{borderColor:'#E8F8FD',borderWidth:'1px',borderStyle:'solid',borderRadius:'10px'}}>
                                <p /*onClick={() =>abrirNuevaPagina(mes,informacion,'nada')}*/ style={{ cursor: 'pointer' }}>
                                <a href="https://www.emapad.gob.ec/app/lotaip/LOTAIP/2021/Adjuntos/OFICIO%20EMAPAD-EP-GG-0059-2022-CML%20%20%20INFORME%20FINAL%20DE%20RENDICION%20DE%20CUENTAS,%20ENERO%20A%20DICIEMBRE%202021.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'black'}}>
                                    INFORME RENDICION DE CUENTA EMAPAD-EP 2021
                                </a>
                                </p>
                            </ListItem>
                    </List>
                    </AccordionDetails>
                    
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                    <Typography sx={{fontWeight:'bold',color:'#4F5051',fontSize:'14'}}  gutterBottom>
                    2022
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <List >
                            <ListItem sx={{borderColor:'#E8F8FD',borderWidth:'1px',borderStyle:'solid',borderRadius:'10px'}}>
                                <p /*onClick={() =>abrirNuevaPagina(mes,informacion,'nada')}*/ style={{ cursor: 'pointer' }}>
                                <a href="https://www.emapad.gob.ec/app/rendiciondecuent/2022/22%20OFICIO%20No.%20EMAPAD-EP-GGE-042-2023-FRG%20INFORME%20FINAL%20DE%20RENDICION%20DE%20CUENTAS%20Ene%20a%20Dic%202022.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'black'}}>
                                    INFORME RENDICION DE CUENTA EMAPAD-EP 2022
                                </a>
                                </p>
                            </ListItem>
                    </List>
                    </AccordionDetails>
                    
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                    <Typography sx={{fontWeight:'bold',color:'#4F5051',fontSize:'14'}}  gutterBottom>
                    2023
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <List >
                            <ListItem sx={{borderColor:'#E8F8FD',borderWidth:'1px',borderStyle:'solid',borderRadius:'10px'}}>
                                <p /*onClick={() =>abrirNuevaPagina(mes,informacion,'nada')}*/ style={{ cursor: 'pointer' }}>
                                <a href="https://www.emapad.gob.ec/app/rendicionCuentas/2023/Informe%20final%20de%20rendicion%20de%20cuentas%20EMAPAD-EP%20-%20copia.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'black'}}>
                                    INFORME RENDICION DE CUENTA EMAPAD-EP 2023
                                </a>
                                </p>
                            </ListItem>
                    </List>
                    </AccordionDetails>
                    
                </Accordion>
            </div>
        </div>
    )
}