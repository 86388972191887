import React, { useState } from 'react';
import '../styles/desplazo.css';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const Desplazo: React.FC<{}> = () => {
    const [activeNav, setActiveNav] = useState<string>("");

    const handleNavClick = (path: string) => {
        setActiveNav(path);
    };

    return (
        <nav className="navbar">
            <ul className="nav-items">
                <li className={`nav-item ${activeNav === "/Home" ? "active" : ""}`} onClick={() => handleNavClick("/Home")}>
                    <Link to="home" className='Link'>
                        <Typography sx={{fontWeight:'bold'}} variant="subtitle2" gutterBottom>
                            HOME
                        </Typography>
                    </Link>
                </li>
                
                <li className={`nav-item ${activeNav === "/Nosotros" ? "active" : ""}`} onClick={() => handleNavClick("/Nosotros")}>
                <Link to="home/Nosotros" className='Link'>
                <Typography sx={{fontWeight:'bold'}} variant="subtitle2" gutterBottom>
                            NOSOTROS
                    </Typography>
                    </Link>
                    </li>
                <li className={`nav-item ${activeNav === "/Servicios" ? "active" : ""}`} onClick={() => handleNavClick("/Servicios")}>
                <Link to="#" className='Link'>
                    <Typography sx={{fontWeight:'bold'}} variant="subtitle2" gutterBottom>
                            SERVICIOS
                    </Typography></Link>
                    <ul className="dropdown-content">
                    <Link to="home/PuntosdePago" className='Link'><li>PUNTOS DE PAGO</li></Link>
                    <Link to="home/Tramites" className='Link'><li>TRAMITES</li></Link>
                    </ul>
                </li>
                <li className={`nav-item ${activeNav === "/Transparencia" ? "active" : ""}`} onClick={() => handleNavClick("/Transparencia")}>
                <Link to="home/Transparencia" className='Link'>
                <Typography sx={{fontWeight:'bold'}} variant="subtitle2" gutterBottom>
                            TRANSPARENCIA
                    </Typography></Link>
                    <ul className="dropdown-content">
                    <Link to="home/Pac" className='Link'><li>P.A.C</li></Link>
                    <Link to="home/Transparencia" className='Link'><li>LOTAIP</li></Link>
                    </ul>
                </li>
                <li className={`nav-item ${activeNav === "/Actualizaciones" ? "active" : ""}`} onClick={() => handleNavClick("/Actualizaciones")}>
                <Link to="home/Actualizacion" className='Link'>
                <Typography sx={{fontWeight:'bold'}} variant="subtitle2" gutterBottom>
                            ACTUALIZACIONES
                    </Typography></Link>
                </li>
                {/* Rndicion de cuentas */}
                <li className={`nav-item ${activeNav === "/Rendicion" ? "active" : ""}`} onClick={() => handleNavClick("/Rendicion")}>
                <Link to="home/Rendicion" className='Link'>
                <Typography sx={{fontWeight:'bold'}} variant="subtitle2" gutterBottom>
                            RENDICION DE CUENTA
                    </Typography></Link>
                </li>
                {/* HORARIO */}
                <li className={`nav-item ${activeNav === "/Horarios" ? "active" : ""}`} onClick={() => handleNavClick("/Horarios")}>
                <Link to="home/Horarios" className='Link'>
                <Typography sx={{fontWeight:'bold'}} variant="subtitle2" gutterBottom>
                            HORARIOS
                    </Typography></Link>
                </li>
                <li className={`nav-item ${activeNav === "/Consulta" ? "active" : ""}`} onClick={() => handleNavClick("/Consulta")}>
                    <Link to="home/Consulta" className='Link'>
                <Typography sx={{fontWeight:'bold'}} variant="subtitle2" gutterBottom>
                            CONSULTA
                    </Typography></Link>
                </li>
                {/* Repite para los demás elementos de la lista, asegurándote de actualizar la ruta en handleNavClick y la comprobación de la clase active */}
            </ul>
        </nav>
    );
};
