import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { List, ListItem } from "@mui/material";
import logo from "../img/emapad.png"
import '../styles/Consulta.css'; 

export const Pac: React.FC<{}> = () => {
    return(
        <div className="consulta-container">
            <div className=" consulta-title">
                <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                    PLAN ANUAL DE CONTRATACIÓN PÚBLICA (PAC)
                 </Typography>
            </div>
           
            <div className="carousel">
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                    <Typography sx={{fontWeight:'bold',color:'#4F5051',fontSize:'14'}}  gutterBottom>
                    PAC 2024
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <List >
                        
                            <ListItem sx={{borderColor:'#E8F8FD',borderWidth:'1px',borderStyle:'solid',borderRadius:'10px'}}>
                                <p /*onClick={() =>abrirNuevaPagina(mes,informacion,'nada')}*/ style={{ cursor: 'pointer' }}>
                                <a href="http://www.emapad.gob.ec/app/lotaip/LOTAIP/2024/13.ADJUNTOS/pac-emapad-ep_2024-signed-signed-signed.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'black'}}>
                                    PAC 2024 EMAPAD-EP
                                </a>
                                </p>
                            </ListItem>
                            <ListItem sx={{borderColor:'#E8F8FD',borderWidth:'1px',borderStyle:'solid',borderRadius:'10px'}}>
                                <p /*onClick={() =>abrirNuevaPagina(mes,informacion,'nada')} */style={{ cursor: 'pointer' }}>
                                <a href="http://www.emapad.gob.ec/app/lotaip/LOTAIP/2024/13.ADJUNTOS/RESOLUCION_No_EMAPAD-EP-GG-2024-02_Aprobacion_del_pac_PAC_2024-signed.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'black'}}>
                                    RESOLUCION ADMINISTRATIVA No.-EMAPAD-EP-GG-2024-002 APROBACION DEL PAC 2024
                                </a>
                                </p>
                            </ListItem>
                            <ListItem sx={{borderColor:'#E8F8FD',borderWidth:'1px',borderStyle:'solid',borderRadius:'10px'}}>
                                <p /* onClick={() =>abrirNuevaPagina(mes,informacion,'nada')} */ style={{ cursor: 'pointer' }}>
                                <a href="http://www.emapad.gob.ec/app/lotaip/LOTAIP/2024/13.ADJUNTOS/Plan%20Anual%20de%20Contrataci%c3%b3n%202024%20SOCE.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'black'}}>
                                    PLAN ANUAL DE CONTRATACION 2024 SOCE
                                </a>
                                </p>
                            </ListItem>
                    
                    </List>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}