import React, { useEffect, useState } from 'react'
import axios from 'axios'; 
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import '../styles/Consulta.css'
import '../styles/Tramite.css'
import c1 from '../img/iconcnombre.png'
import c2 from '../img/iconcdireccion.png'
import c3 from '../img/tarifa.png'
import c4 from '../img/actualizacion.png'
import c5 from '../img/iconp.png'
import c6 from '../img/iconps.png'
import c7 from '../img/iconfa.png'
import c8 from '../img/iconvc.png'



export const Actualizacion: React.FC<{}> = ()=>{
    const [openDialogs, setOpenDialogs] = useState({
        cambioNombre: false,
        direccionErrada: false,
        cambioTarifa: false,
        actualizacionDatos: false,
    });

 // Función para manejar la apertura de los diálogos
 const handleClickOpen = (dialogKey: keyof typeof openDialogs) => {
    setOpenDialogs({ ...openDialogs, [dialogKey]: true });
};

// Función para manejar el cierre de los diálogos
const handleClose = (dialogKey: keyof typeof openDialogs) => {
    setOpenDialogs({ ...openDialogs, [dialogKey]: false });
};



    const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedFile) {
      const formData = new FormData();
      formData.append('fileToUpload', selectedFile);

      try {
        // Utiliza Axios para enviar la solicitud POST
        const response = await axios.post('https://www.emapad.gob.ec/app/conexion/docapi/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // Maneja la respuesta de la API aquí
        console.log('Archivo subido con éxito:', response.data);
      } catch (error) {
        // Maneja el error aquí
        console.error('Error al intentar enviar el archivo:', error);
        
      }
    }
  };

  const [alertOpen, setAlertOpen] = useState(false);
  let timer: ReturnType<typeof setTimeout> | undefined;



  const handleAlert = ()=>{
    setAlertOpen(true)

    // Cierra la alerta después de 30 segundos
  timer = setTimeout(() => {
    setAlertOpen(false);
    timer = undefined;
  }, 8000); // 30000 milisegundos = 30 segundos
  }

  useEffect(() => {
    return () => {
      // Limpia el temporizador cuando el componente se desmonta
      if (timer !== undefined) {
        clearTimeout(timer);
      }
    };
  }, []);


    return(
        <div className="consulta-container">
            {alertOpen && (
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="filled" severity="success" onClose={() => setAlertOpen(false)}>
                    Informacion enviada exitosamente, muy pronto nos comunicaremos contigo por correo
                </Alert>
            </Stack>
            )}
        <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"30px"}}>
            <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                ACTUALIZACIONES
            </Typography>
        </div>
        <div className='card-div'>
            <div className='card-item'>
                <div className='card-item-principal'>
                    <img src={c1} alt="30%" className='card-item-img'/>
                    <h6>CAMBIO DE NOMBRE</h6>
                
                <React.Fragment>
                <Button className='b-actulizar' variant="outlined" onClick={()=>handleClickOpen('cambioNombre')}>
                    ACTUALIZAR
                </Button>
                <Dialog
                    open={openDialogs.cambioNombre}
                    onClose={()=>handleClose('cambioNombre')}
                    PaperProps={{
                    component: 'form',
                    onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const cedula = formData.get('cedula');
                        const servicio = formData.get('servicio');
                        const celular = formData.get('celular');
                        const correo = formData.get('correo');
                      
                        // Asumiendo que 'cedulaPDF' y 'predioPDF' son los nombres de los campos para los archivos en tu formulario
                        const cedulaPDF = event.currentTarget.cedulaPDF.files[0]; // Obtiene el primer archivo para 'cedulaPDF'.
                        const predioPDF = event.currentTarget.predioPDF.files[0]; // Obtiene el primer archivo para 'impuestoPredialPDF'.
                      
                        // Agrega los archivos al objeto formData si existen
                        if (cedulaPDF) formData.append('cedulaPDF', cedulaPDF, cedulaPDF.name);
                        if (predioPDF) formData.append('predioPDF', predioPDF, predioPDF.name);
                      
                        // Muestra el contenido de formData para depuración
                        formData.forEach((value, key) => {
                            console.log(`${key}: ${value}`);
                          });
                      
                        try {
                          // Utiliza Axios para enviar la solicitud POST, asegúrate de pasar la instancia correcta 'formData'
                          const response = await axios.post('https://www.emapad.gob.ec/app/conexion/docapi/', formData, {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                          });
                      
                          // Maneja la respuesta de la API aquí
                          console.log('Archivo subido con éxito:', response.data);
                          handleAlert()
                          
                        } catch (error) {
                          // Maneja el error aquí
                          console.error('Error al intentar enviar el archivo:', error);
                        }
                        handleClose('cambioNombre');
                      },
                    }}
                >
                    <DialogTitle>CAMBIO DE NOMBRE</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Ingresa tus datos y documentos necesarios.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="cedula"
                        name="cedula"
                        label="Ingrese su número de cedula"
                        type="number"
                        fullWidth
                        variant="standard"
                    />
                     <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="servicio"
                        name="servicio"
                        label="Ingrese su Codigo de Servicio"
                        type="number"
                        fullWidth
                        variant="standard"
                        className='inumber'
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="celular"
                        name="celular"
                        label="Ingrese su Numero de Celular"
                        type="number"
                        fullWidth
                        variant="standard"
                        className='inumber'
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="correo"
                        name="correo"
                        label="Ingrese su Correo"
                        type="email"
                        fullWidth
                        variant="standard"
                        className='inumber'
                    />
                    <label htmlFor="cedulaPDF" className="custom-file-upload">
                        Subir PDF de la cédula
                    </label>
                    <input required type="file" id="cedulaPDF" name="cedulaPDF" className='upload-box' accept="application/pdf"  />
                    <label htmlFor="cedulaPDF" className="custom-file-upload">
                        Subir PDF de Impuesto Predial
                    </label>
                    <input required type="file" id="predioPDF" name="predioPDF" className='upload-box' accept="application/pdf"  />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>handleClose('cambioNombre')}>Cancel</Button>
                    <Button type="submit">Enviar Información</Button>
                    </DialogActions>
                </Dialog>
                </React.Fragment>
                </div>
            </div>
              
            {/* FORMULARIO DIRECCION ERRADA */}
            
            <div className='card-item'>
            
                <div className='card-item-principal'>
                    <img src={c2} alt="30%" className='card-item-img'/>
                    <h6>DIRECCION ERRADA</h6>
                
                <React.Fragment>
                <Button className='b-actulizar' variant="outlined" onClick={()=>handleClickOpen('direccionErrada')}>
                    ACTUALIZAR
                </Button>
                <Dialog
                    open={openDialogs.direccionErrada}
                    onClose={()=>handleClose('direccionErrada')}
                    PaperProps={{
                    component: 'form',
                    onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const cedula = formData.get('cedula');
                        const servicio = formData.get('servicio');
                        const celular = formData.get('celular');
                        const correo = formData.get('correo');
                      
                        // Asumiendo que 'cedulaPDF' y 'predioPDF' son los nombres de los campos para los archivos en tu formulario
                        const cedulaPDF = event.currentTarget.cedulaPDF.files[0]; // Obtiene el primer archivo para 'cedulaPDF'.
                        const predioPDF = event.currentTarget.predioPDF.files[0]; // Obtiene el primer archivo para 'impuestoPredialPDF'.
                      
                        // Agrega los archivos al objeto formData si existen
                        if (cedulaPDF) formData.append('cedulaPDF', cedulaPDF, cedulaPDF.name);
                        if (predioPDF) formData.append('predioPDF', predioPDF, predioPDF.name);
                      
                        // Muestra el contenido de formData para depuración
                        formData.forEach((value, key) => {
                            console.log(`${key}: ${value}`);
                          });
                      
                        try {
                          // Utiliza Axios para enviar la solicitud POST, asegúrate de pasar la instancia correcta 'formData'
                          const response = await axios.post('https://www.emapad.gob.ec/app/conexion/docapidireccion/', formData, {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                          });
                      
                          // Maneja la respuesta de la API aquí
                          console.log('Archivo subido con éxito:', response.data);
                          handleAlert()
                          
                        } catch (error) {
                          // Maneja el error aquí
                          console.error('Error al intentar enviar el archivo:', error);
                        }
                        handleClose('direccionErrada');
                      },
                    }}
                >
                    <DialogTitle>DIRECCION ERRADA</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Ingresa tus datos y documentos necesarios.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="cedula"
                        name="cedula"
                        label="Ingrese su número de cedula"
                        type="number"
                        fullWidth
                        variant="standard"
                    />
                     <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="servicio"
                        name="servicio"
                        label="Ingrese su Codigo de Servicio"
                        type="number"
                        fullWidth
                        variant="standard"
                        className='inumber'
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="celular"
                        name="celular"
                        label="Ingrese su Numero de Celular"
                        type="number"
                        fullWidth
                        variant="standard"
                        className='inumber'
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="correo"
                        name="correo"
                        label="Ingrese su Correo"
                        type="email"
                        fullWidth
                        variant="standard"
                        className='inumber'
                    />
                    <label htmlFor="cedulaPDF" className="custom-file-upload">
                        Subir PDF de la cédula
                    </label>
                    <input required type="file" id="cedulaPDF" name="cedulaPDF" className='upload-box' accept="application/pdf"  />
                    <label htmlFor="cedulaPDF" className="custom-file-upload">
                        Subir PDF de Impuesto Predial
                    </label>
                    <input required type="file" id="predioPDF" name="predioPDF" className='upload-box' accept="application/pdf"  />

                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>handleClose('direccionErrada')}>Cancel</Button>
                    <Button type="submit">Enviar Información</Button>
                    </DialogActions>
                </Dialog>
                </React.Fragment>
                </div>
            </div>
            


            {/* FORMULARIO CAMBIO DE TARIFA */}
            
            <div className='card-item'>
            
                <div className='card-item-principal'>
                    <img src={c3} alt="30%" className='card-item-img'/>
                    <h6>CAMBIO DE TARIFA</h6>
                
                <React.Fragment>
                <Button className='b-actulizar' variant="outlined" onClick={()=>handleClickOpen('cambioTarifa')}>
                    ACTUALIZAR
                </Button>
                <Dialog
                    open={openDialogs.cambioTarifa}
                    onClose={()=>handleClose('cambioTarifa')}
                    PaperProps={{
                    component: 'form',
                    onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const cedula = formData.get('cedula');
                        const servicio = formData.get('servicio');
                        const celular = formData.get('celular');
                        const correo = formData.get('correo');
                      
                        // Asumiendo que 'cedulaPDF' y 'predioPDF' son los nombres de los campos para los archivos en tu formulario
                        const cedulaPDF = event.currentTarget.cedulaPDF.files[0]; // Obtiene el primer archivo para 'cedulaPDF'.
                        const predioPDF = event.currentTarget.predioPDF.files[0]; // Obtiene el primer archivo para 'impuestoPredialPDF'.
                        const foto = event.currentTarget.foto.files[0]; // Obtiene el primer archivo para 'impuestoPredialPDF'.
                      
                        // Agrega los archivos al objeto formData si existen
                        if (cedulaPDF) formData.append('cedulaPDF', cedulaPDF, cedulaPDF.name);
                        if (predioPDF) formData.append('predioPDF', predioPDF, predioPDF.name);
                        if (foto) formData.append('foto', foto, foto.name);
                      
                        // Muestra el contenido de formData para depuración
                        formData.forEach((value, key) => {
                            console.log(`${key}: ${value}`);
                          });
                      
                        try {
                          // Utiliza Axios para enviar la solicitud POST, asegúrate de pasar la instancia correcta 'formData'
                          const response = await axios.post('https://www.emapad.gob.ec/app/conexion/docapitarifa/', formData, {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                          });
                      
                          // Maneja la respuesta de la API aquí
                          console.log('Archivo subido con éxito:', response.data);
                          handleAlert()
                          
                        } catch (error) {
                          // Maneja el error aquí
                          console.error('Error al intentar enviar el archivo:', error);
                        }
                        handleClose('cambioTarifa');
                      },
                    }}
                >
                    <DialogTitle>CAMBIO DE TARIFA</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Ingresa tus datos y documentos necesarios.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="cedula"
                        name="cedula"
                        label="Ingrese su número de cedula"
                        type="number"
                        fullWidth
                        variant="standard"
                    />
                     <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="servicio"
                        name="servicio"
                        label="Ingrese su Codigo de Servicio"
                        type="number"
                        fullWidth
                        variant="standard"
                        className='inumber'
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="celular"
                        name="celular"
                        label="Ingrese su Numero de Celular"
                        type="number"
                        fullWidth
                        variant="standard"
                        className='inumber'
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="correo"
                        name="correo"
                        label="Ingrese su Correo"
                        type="email"
                        fullWidth
                        variant="standard"
                        className='inumber'
                    />
                    <label htmlFor="cedulaPDF" className="custom-file-upload">
                        Subir PDF de la cédula
                    </label>
                    <input required type="file" id="cedulaPDF" name="cedulaPDF" className='upload-box' accept="application/pdf"  />
                    <label htmlFor="cedulaPDF" className="custom-file-upload">
                        Subir PDF de Impuesto Predial
                    </label>
                    <input required type="file" id="predioPDF" name="predioPDF" className='upload-box' accept="application/pdf"  />
                    <label htmlFor="cedulaPDF" className="custom-file-upload">
                        Subir Fotografia del Lugar
                    </label>
                    <input required type="file" id="foto" name="foto" className='upload-box' accept="image/jpeg"  />
                    

                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>handleClose('cambioTarifa')}>Cancel</Button>
                    <Button type="submit">Enviar Información</Button>
                    </DialogActions>
                </Dialog>
                </React.Fragment>
                </div>
            </div>


             {/* FORMULARIO ACTUALIZACION */}
            
             <div className='card-item'>
            
            <div className='card-item-principal'>
                <img src={c4} alt="30%" className='card-item-img'/>
                <h6>ACTUALIZACION DE DATOS</h6>
            
            <React.Fragment>
            <Button className='b-actulizar' variant="outlined" onClick={()=>handleClickOpen('actualizacionDatos')}>
                ACTUALIZAR
            </Button>
            <Dialog
                open={openDialogs.actualizacionDatos}
                onClose={()=>handleClose('actualizacionDatos')}
                PaperProps={{
                component: 'form',
                onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const cedula = formData.get('cedula');
                    const servicio = formData.get('servicio');
                    const celular = formData.get('celular');
                    const correo = formData.get('correo');
                  
                    // Asumiendo que 'cedulaPDF' y 'predioPDF' son los nombres de los campos para los archivos en tu formulario
                    const cedulaPDF = event.currentTarget.cedulaPDF.files[0]; // Obtiene el primer archivo para 'cedulaPDF'.
                    const predioPDF = event.currentTarget.predioPDF.files[0]; // Obtiene el primer archivo para 'impuestoPredialPDF'.
                  
                    // Agrega los archivos al objeto formData si existen
                    if (cedulaPDF) formData.append('cedulaPDF', cedulaPDF, cedulaPDF.name);
                    if (predioPDF) formData.append('predioPDF', predioPDF, predioPDF.name);
                  
                    // Muestra el contenido de formData para depuración
                    formData.forEach((value, key) => {
                        console.log(`${key}: ${value}`);
                      });
                  
                    try {
                      // Utiliza Axios para enviar la solicitud POST, asegúrate de pasar la instancia correcta 'formData'
                      const response = await axios.post('https://www.emapad.gob.ec/app/conexion/docapicenso/', formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                      });
                  
                      // Maneja la respuesta de la API aquí
                      console.log('Archivo subido con éxito:', response.data);
                      handleAlert()
                      
                    } catch (error) {
                      // Maneja el error aquí
                      console.error('Error al intentar enviar el archivo:', error);
                    }
                    handleClose('actualizacionDatos');
                  },
                }}
            >
                <DialogTitle>ACTUALIZACION DE DATOS</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Ingresa tus datos y documentos necesarios.
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="cedula"
                    name="cedula"
                    label="Ingrese su número de cedula"
                    type="number"
                    fullWidth
                    variant="standard"
                />
                 <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="servicio"
                    name="servicio"
                    label="Ingrese su Codigo de Servicio"
                    type="number"
                    fullWidth
                    variant="standard"
                    className='inumber'
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="celular"
                    name="celular"
                    label="Ingrese su Numero de Celular"
                    type="number"
                    fullWidth
                    variant="standard"
                    className='inumber'
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="correo"
                    name="correo"
                    label="Ingrese su Correo"
                    type="email"
                    fullWidth
                    variant="standard"
                    className='inumber'
                />
                <label htmlFor="cedulaPDF" className="custom-file-upload">
                    Subir PDF de la cédula
                </label>
                <input required type="file" id="cedulaPDF" name="cedulaPDF" className='upload-box' accept="application/pdf"  />
                <label htmlFor="cedulaPDF" className="custom-file-upload">
                    Subir PDF de Impuesto Predial
                </label>
                <input required type="file" id="predioPDF" name="predioPDF" className='upload-box' accept="application/pdf"  />

                </DialogContent>
                <DialogActions>
                <Button onClick={()=>handleClose('actualizacionDatos')}>Cancel</Button>
                <Button type="submit">Enviar Información</Button>
                </DialogActions>
            </Dialog>
            </React.Fragment>
            </div>
        </div>
            
            
            
        </div>

        









            
        </div>
    )
}